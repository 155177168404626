<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
<!--
          default-active="/enterprise/enterprise-info"-->
      <el-menu
          :default-active="$route.path.substring(10)"
          :collapse="isCollapse"
          :background-color="variables.menuBg"
          :text-color="variables.menuText"
          :unique-opened="true"
          :active-text-color="variables.menuActiveText"
          :collapse-transition="false"
          :default-openeds="openeds"
          mode="vertical">
<!--        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path"/>-->
        <sidebar-item v-for="route in menuList" :key="route.id" :item="route" :base-path="route.path"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import service from "@/api/login";

export default {
  components: {SidebarItem, Logo},
  data(){
    return {
      menuList: [],
    }
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    // menu(){
    //   return this.$store.state.user.menuList;
    // },
    routes() {
      // 工作台路径: /workspace
      let workspaceInx = this.$router.options.routes.findIndex(item => item.path === '/workspace');
      if (workspaceInx === -1) {
        return this.$router.options.routes
      } else {
        // SideBar 只显示工作台下的路由
        let routers = this.$router.options.routes[workspaceInx].children;
        return routers;
      }
    },
    activeMenu() {
      const route = this.$route
      const {meta, path} = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    openeds() {
      let url = this.menuList && this.menuList[0] && this.menuList[0].resUrl
      var arr = [url]
      return arr
    }
  },
  mounted() {
    this.sidebarData();
  },
  methods: {
    // 接口菜单格式 转 路由格式
    // 递归处理 Children 数据
    resoruceToRoute(list) {
      if (!list || !list.length) {
        return []
      }

      list = list.filter(item => !!item.resUrl)

      list = list.map(item => {
        let children = []
        if (item.children) {
          children = this.resoruceToRoute(item.children)
        }
        let routerName = item.resUrl.replace("/","")
        // console.log(routerName)
        return {
          id: item.id,
          path: routerName,
          meta: {
            title: item.name,
            icon: item.icon
          },
          children
        }
      })

      return list
    },

    sidebarData(){
      service.getMenus().then(resp => {
        this.menuList = this.resoruceToRoute(resp.data.resourceVos);
        let url = this.menuList && this.menuList[0] && this.menuList[0].resUrl
        this.$store.commit('user/SET_MENU', [url])
        this.$store.commit('user/SET_MENU_All', resp.data.resourceVos)
      });
    },


  }
}
</script>
<style scoped>
  >>>.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
</style>

<!--
<style lang="scss" scoped>
.el-menu {
  margin-bottom: 100px;
}
</style>
-->
