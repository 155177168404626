<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" :to="{ name: 'console' }">
        <img v-if="logo" :src="logoC" class="sidebar-logo">
<!--        <h1 v-else class="sidebar-title">{{ '日日顺' }} </h1>-->
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" :to="{ name: 'console' }">
        <img v-if="logo" :src="logo" class="sidebar-logo">
<!--        <h1 class="sidebar-title">{{ '日日顺' }} </h1>-->
      </router-link>
    </transition>
  </div>
</template>

<script>
import logoImgActive from '@/assets/images/logoin.png'
import logoImgCollapse from '@/assets/images/logoinC.png'
import { title } from '@/settings'

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      default: true,
      required: true
    }
  },
  data() {
    return {
      title,
      logo: logoImgActive,
      logoC:logoImgCollapse,

    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: $menuLogoBg;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      //width: 110px;
      height: 27px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: $menuLogoText;
      font-weight: 500;
      line-height: 50px;
      font-size: 14px;
      // font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
