<template>
  <div class="user-drop">
    <el-dropdown trigger="hover" @command="dropdownClick">
      <div class="avatar-wrapper">
        <img :src="sex === 0 ? girl : boy" class="user-avatar" alt="头像">
        <i class="el-icon-caret-bottom"/>
      </div>
      <el-dropdown-menu slot="dropdown">
<!--        <el-dropdown-item-->
<!--            command="home"-->
<!--            icon="el-icon-s-home"-->
<!--        >首页-->
<!--        </el-dropdown-item>-->
<!--        <el-dropdown-item-->
<!--          command="resetPsw"-->
<!--          icon="el-icon-unlock"-->
<!--        >修改密码-->
<!--        </el-dropdown-item>-->
        <el-dropdown-item
          command="logout"
          divided
          icon="el-icon-switch-button"
        >退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import {Message} from 'element-ui'
  import service from '@/api/login'
  import avatar from '@/assets/images/avatar.jpg'
  import girl from '@/assets/header-girl.png'
  import boy from '@/assets/header-boy.png'

  export default {
    name: 'UserDropdown',
    data() {
      return {
        stateVisble: false,
        avatar: avatar,
        boy: boy,
        girl: girl,
      }
    },
    computed: {
      // ...mapState({
      //   avatar: state => state.user.avatar
      // }),
      token() {
        return localStorage.getItem('token')
      },
      sex(){
        return this.$store.state.user.sex;
      }

    },
    methods: {
      dropdownClick(command){
        if(command === 'logout'){
          this.logout();
        }else if(command === 'resetPsw'){
          this.$router.push({name: 'restPsw'})
        }

      },
      logout() {
        service.logOut({token: this.token}).then(resp => {
          if(resp.success){
            localStorage.removeItem('token'); //localStorage uuctoken信息
            this.$store.commit('user/SET_TOKEN','');
            this.pushLogin()
          }
        })
      },
      pushLogin(){
        sessionStorage.clear()
        window.location.href = process.env.VUE_APP_IAM_LOGIN_API + '/?project=haier-rrs-login-page&sync-third-token=false&client_id=' + process.env.VUE_APP_IAM_CLIENT_ID + '&redirect_uri=' + process.env.VUE_APP_GMS_URL + '&exit_url=' + process.env.VUE_APP_GMS_URL + '&sync-third-token=false#exit'
      },
    }
  }
</script>

<style lang="scss" scoped>
  .avatar-wrapper {
    position: relative;

    .user-avatar {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      cursor: pointer;
    }

    .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 14px;
      font-size: 12px;
    }
  }
</style>
