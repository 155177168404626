<template>
  <div class="navbar">
    <!-- <logo class="navbar-logo" :collapse="false" /> -->

    <hamburger
      :is-active="sidebar.opened"
      :class="['hamburger-container', menuBtnToggle ? 'active' : '']"
      @toggleClick="toggleSideBar"
    />
    <breadcrumb class="breadcrumb-container" />
    <div class="right-menu">
      <span class="name">{{ name || '用户' }}</span>
      <user-dropdown class="avatar-container" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import avatar from '@/assets/images/head-default.jpg'
import UserDropdown from '@/components/Header/user-dropdown'

export default {
  components: { Breadcrumb, Hamburger, UserDropdown },
  data() {
    return {
      avatar,
      menuBtnToggle: false,
      blockVisible: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name',
    ])
  },
  created() {
    if (this.$route.path.includes('/workspace/')) {
      this.menuBtnToggle = true
    } else {
      this.menuBtnToggle = false
    }
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    // 企业切换回调
    addblock() {
      this.blockVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
$height: 50px;
$bgColor: #2A2B2C;

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow:0px 0px 10px 5px rgba(5, 3, 20, 0.04);

  .navbar-logo {
    max-width: $sideBarWidth;
    float: left;
  }

  .hamburger-container {
    visibility: hidden;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;
    &.active {
      visibility: visible;
    }

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }
  .left-orgname{
    float: left;
    margin-left: 100px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
  .menu-cnt {
    display: flex;
    align-items: center;
    margin-left: 100px;
    float: left;
    > li {
      box-sizing: border-box;
      margin-left: 50px;
      padding: 0 10px;
      height: $height;
      line-height: $height;
      font-size: 16px;
      border-bottom: 4px solid #fff;
      transition: all 0.4s;
      cursor: pointer;
      &:first-child {
        margin-left: 0;
      }

      &:hover,
      &.active {
        color: $primary;
      }
      &.active {
        border-bottom-color: $primary;
      }
    }
  }

  .right-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    float: right;
    height: 100%;
    padding: 5px 0;

    &:focus {
      outline: none;
    }

    .name {
      color: $gray;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-left: 10px;
      margin-right: 30px;
    }
  }
}
</style>
