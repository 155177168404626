<script>
  export default {
    name: 'MenuItem',
    functional: true,
    props: {
      icon: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      }
    },
    render(h, context) {
      const {icon, title} = context.props
      const vnodes = []

      if (icon) {
        vnodes.push(<svg-icon icon={icon} class-name='icon'/>)
        // vnodes.push(<i className="el-icon-edit"></i>)
      }

      if (title) {
        vnodes.push(<span slot='title'>{(title)}</span>)
      }
      return vnodes
    }
  }
</script>

<style lang="scss" scoped>
  .icon {
    font-size: 15px;
    color: white;
    vertical-align: middle !important;
  }
</style>
